import crudHandlers from "./_crud";

const initialState = {
    entities: {},
    relWaitersById: null,
    assistances: [],
    allNotifications: 0,
    restroomNotifications: 0,
    billNotifications: 0,
    clientAtentionNotifications: 0,
    is_fetching: false,
    active: undefined,
}

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'LOAD_ATTENTIONS':
            return{
                ...state,
                allNotifications: payload?.allNotifications??0,
                restroomNotifications: payload?.restroomNotifications??0,
                billNotifications: payload?.billNotifications,
                clientAtentionNotifications: payload?.clientAtentionNotifications,
                is_fetching: false,
            }
        case 'LOAD_ASSISTANCES': 
            return{
                ...state,
                assistances: payload,
            }
        default:

            state = crudHandlers(state, action, 'rel_waiter_table')

            return state
    }
}