import React, { useState, useEffect } from 'react'

import {
	Accordion,
	Popup,
	Icon,
	Grid,
	Menu,
	List,
	Modal,
	Segment,
	Header,
	Divider,
	Label,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import './Sidebar.scss'
import styles from './../../styles/global.module.scss'

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window
	return {
		width,
		height,
	}
}

const SidebarMenu = (props) => {
	const {
		expand,
		setExpand,
		previousExpand,
		setPreviousExpand,
		user,
		rest,
		history,
		location,
		openModal,
		setOpenModal,
		mobileOnly,
		setMobileOnly,
	} = props

	const [activeHeaderIndex, setActiveHeaderIndex] = useState(-1)
	const [activeSubHeaderIndex, setActiveSubHeaderIndex] = useState(-1)
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	)

	useEffect(() => {
		function handleResize() {
			if (windowDimensions !== getWindowDimensions())
				setWindowDimensions(getWindowDimensions())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		if (windowDimensions.width <= 425) {
			setMobileOnly(true)
			if (expand === 2 || expand == null) setExpand(1)
		} else {
			setMobileOnly(false)
		}
	}, [windowDimensions])

	useEffect(() => {
		if (location) {
			!mobileOnly ? setExpand(0) : setExpand(1)
		}
	}, [location])

	const handleHeaderClick = (event, title) => {
		event.preventDefault()
		event.stopPropagation()
		const { index } = title
		const activeIndex = activeHeaderIndex
		const newIndex = activeIndex === index ? -1 : index
		setActiveHeaderIndex(newIndex)
	}

	const handleSubHeaderClick = (event, title) => {
		event.preventDefault()
		event.stopPropagation()
		const { index } = title
		const activeIndex = activeSubHeaderIndex
		const newIndex = activeIndex === index ? -1 : index
		setActiveSubHeaderIndex(newIndex)
	}

	const modulesToRender = user?.modules?.filter((mod) => {
		const { permisitions } = mod
		return permisitions?.ACCESS
	})

	const headers = modulesToRender?.reduce((accu, module) => {
		const x = accu.find((item) => item.module_group == module.module_group)
		if (!x) {
			return accu.concat([module])
		} else {
			return accu
		}
	}, [])

	const subHeaders = _.uniq(
		modulesToRender?.reduce((accu, module) => {
			const x = accu?.find((item) => item?.sub_group == module?.sub_group)
			if (!x) {
				if (module.sub_group) {
					return accu.concat([
						{
							title: module?.sub_group,
							group: module?.module_group,
							sub_group: module?.sub_group,
						},
					])
				} else {
					return accu
				}
			} else {
				return accu
			}
		}, [])
	)

	const routes = modulesToRender
		?.map((m) => {
			if (m.isVisible) {
				return {
					title: m.name,
					to: m.url,
					group: m.module_group,
					sub_group: m.sub_group,
				}
			}
		})
		.filter((mf) => mf != null)

	return (
		<>
			<div
				className='sidebar'
				style={
					expand == null || expand == 2
						? { width: '250px' }
						: expand == 1
						? { width: '55px' }
						: null
				}
			>
				{headers?.map((header, index) => (
					<Accordion
						key={`accordion-${header.module_group}-${index}`}
						fluid
						style={{ paddingLeft: '12px', paddingBottom: '5px' }}
					>
						<Accordion.Title
							key={`title-${header.module_group}-${index}`}
							active={activeHeaderIndex === index}
							index={index}
							onClick={handleHeaderClick}
						>
							<Popup
								trigger={
									<Menu.Item
										className={`${styles.shrinkOnHover} SubMenu__Item`}
										style={{ width: '100%', display: 'inline-block' }}
									>
										<Icon
											name={header.icon}
											size='large'
											className='SideBar__Outline'
										/>
										{(expand == null || expand == 2) && (
											<h5 className='menu-row-title'>{header.module_group}</h5>
										)}
									</Menu.Item>
								}
								positionFixed
								position='right center'
								pinned
								hoverable
								closeOnTriggerClick={true}
								offset={[0, 0]}
								className='SubMenu__Content'
								content={
									<Grid divided='vertically' style={{ width: '15rem' }}>
										<Grid.Row style={{ paddingBottom: 0 }}>
											<Grid.Column>
												<h4>{header.module_group}</h4>
											</Grid.Column>
										</Grid.Row>
										<Grid.Row style={{ paddingTop: '0', paddingBottom: '0' }}>
											<Grid.Column>
												<Accordion.Content
													key={`content-${header.module_group}-${index}`}
													style={{ marginTop: '0px', paddingTop: '0px' }}
													active={activeHeaderIndex === index}
												>
													{subHeaders.map((subHeader, indexH) => {
														if (subHeader.group == header.module_group)
															return (
																<Accordion
																	style={{
																		marginTop: '0px',
																		paddingTop: '0px',
																	}}
																	key={`accordion-${subHeader.title}-${indexH}`}
																	fluid
																	className='menu_row'
																>
																	<Accordion.Title
																		key={`title-${subHeader.title}-${indexH}`}
																		style={{
																			marginBottom: '0px',
																			paddingBottom: '0px',
																		}}
																		active={activeSubHeaderIndex === indexH}
																		index={indexH}
																		onClick={handleSubHeaderClick}
																	>
																		<Icon name='dropdown' />
																		{subHeader.title}
																	</Accordion.Title>
																	<Accordion.Content
																		key={`content-${subHeader.title}-${indexH}`}
																		style={{
																			marginTop: '0px',
																			paddingTop: '0px',
																		}}
																		active={activeSubHeaderIndex === indexH}
																	>
																		<Menu
																			vertical
																			fluid
																			text
																			style={{ paddingLeft: '30px' }}
																		>
																			{routes.map((route, indexR) => {
																				if (route.sub_group === subHeader.title)
																					return (
																						<Link
																							key={`link-${indexR}-${route.title}`}
																							to={`/${route.to}`}
																							className='sub_menu_row'
																						>
																							<Menu.Item
																								className={styles.growOnHover}
																								style={{ margin: '0' }}
																							>
																								{route.title}
																							</Menu.Item>
																						</Link>
																					)
																			})}
																		</Menu>
																	</Accordion.Content>
																</Accordion>
															)
													})}
													<Menu vertical fluid text style={{ width: '100%' }}>
														{
															routes
															.filter(r=>r.group == header.module_group &&
																!r.sub_group)
															.sort((a, b) => {
																if (a.title > b.title) {
																	return 1;
																  }
																  if (a.title < b.title) {
																	return -1;
																  }
																  // a must be equal to b
																  return 0;
															})
															.map((route, indexR) => (
																<Link
																	key={`link-${indexR}-${route.title}`}
																	to={`/${route.to}`}
																	className='sub_menu_row'
																>
																	<Menu.Item style={{ margin: '0' }}>
																		{route.title}
																	</Menu.Item>
																</Link>
															))
														}
													</Menu>
												</Accordion.Content>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								}
							/>
						</Accordion.Title>
					</Accordion>
				))}
			</div>
		</>
	)
}

export default SidebarMenu
