const initialState = {
    event : [],
    eventById : null,
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case 'LOAD_EVENT':
            return { ...state, event: payload }
        case 'LOAD_EVENT_BY_ID':
            return { ...state, eventById: payload }
            
        default:
           

            return state
    }
}