import { showSuccessMessage, showErrorMessage } from 'utils/messages'
import { callApi } from 'utils/api'

import crud from "./_crud";

const actions = crud('rel_waiter_table', 'la rel_waiter_table', 'las rel_waiter_tables');

const getWaiterTableRelations = () => {
	return async (dispatch, getState) => {
		const state = getState()
		const sys_company_id = state.app.user.sys_company_id;
		console.log(sys_company_id);
		try {
			const res = await callApi(`/rel_waiter_table/waiter_table_relations`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			if (res.status === 200) {
				console.log("RELATION 1: " + JSON.stringify(res.body));
				dispatch({
					type: 'WAITER_TABLE_RELATION_CREATED',
					payload: res.body,
				})
			} else {
				throw new Error('Error al obtener las relaciones')
			}
		} catch (error) {
			console.log(error.message)
			showErrorMessage('Error al obtener las relaciones de mesero y mesas')
		}
	}
}

const createWaiterTableRelation = ({ personal_id, table_ids }, callback) => {
	return async (dispatch, getState) => {
		const state = getState()
		const sys_company_id = state.app.user.sys_company_id;
		try {
			const res = await callApi(`/rel_waiter_table/create_rel_waiter_table`, {
				method: 'POST',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify({
					sys_company_id,
					personal_id,
					table_ids,
				}),
			})

			if (res.status === 200) {
				if (callback && typeof callback === 'function') {
					callback(true)
				}
			} else {
				throw new Error('Error al crear la relación')
			}
		} catch (error) {
			console.log(error.message)
			if (callback && typeof callback === 'function') {
				callback(false)
			}
		}
	}
}

const getAttention = (params) => {
	return async (dispatch, getState) => {
		const state = getState()
		try{
			const res = await callApi('/rel_waiter_table/get_attention_times/day_week_month_year', {
				headers: {
					Authorization: state.app.user?.token
				},
				params,
			})
			dispatch({
				type: 'LOAD_ATTENTIONS',
				payload: res.body
			})
		}catch(e){
			console.error('Error en getAttention', e)
		}
	}
}

const loadAsistances = (startDate, endDate) => {
	return async (dispatch, getState) => {
		const state = getState()
		try{
			const res = await callApi('/rel_waiter_table/get/assistances', {
				headers: {
					Authorization: state.app.user?.token
				},
				params: {
					startDate,
					endDate,
				},
			})
			if(res.status==200){
				dispatch({
					type: 'LOAD_ASSISTANCES',
					payload: res.body
				})
			}
		}catch(e){
			console.error('Error en getAttention', e)
		}
	}
}

export default { ...actions, getWaiterTableRelations, createWaiterTableRelation, getAttention, loadAsistances }
