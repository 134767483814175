import React, { useState, useEffect } from 'react'
import { Button, Card, Segment, Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import Input from 'components/InputForm'
import { useDispatch } from 'react-redux'
import { registerClient } from '../../store/actions/clients'


import LogoImg from '../../images/logo1024.jpg'



const validate = (values) => {
	const errors = {}
	if (!values.name) {
		errors.name = 'Campo obligatorio'
	}
	if (!values.cellphone) {
		errors.cellphone = 'Campo obligatorio'
	} else {
		if (String(values.cellphone).length !== 10) {
			errors.cellphone = 'Formato incorrecto: Número a 10 dígitos'
		}
	}
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
	if (!values.email) {
		errors.email = 'Campo obligatorio'
	}else {
		if (!emailRegex.test(values.email)) {
			errors.email = 'Formato incorrecto: Ingrese un formato de correo válido'
		}
	}
	if (values.password !== values.passwordConfirm) {
		errors.passwordConfirm = 'La contraseña no coincide'
	}
	if (!values.password) {
		errors.password = 'Campo obligatorio'
	}
	return errors
}

const handleCellphoneInput = (event) => {
	let value = event.target.value

	value = value.replace(/[^0-9]/g, '')

	if (value.length > 10) {
		value = value.slice(0, 10)
	}

	event.target.value = value
}

const ClientRegister = (props) => {
	const dispatch = useDispatch()

	return (
		<div
			style={{
				width: '100wh',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px',
			}}
			className='login-background'
		>
			<div
				style={{
					display: 'flex',
					height: '20vh',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			></div>
			<Grid centered>
				<Grid.Row>
					<Grid.Column computer={6} tablet={8} mobile={16}>
						<Segment>
							<Grid stackable>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Image
										src={process.env.REACT_APP_LOGO_LOGIN_URL ?? LogoImg}
										size='medium'
									/>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Formik
										initialValues={{}}
										validate={validate}
										onSubmit={async (values, { setSubmitting }) => {
											const obj = {
												name: values.name,
												cellphone: values.cellphone,
												email: values.email,
												password: values.password,
											}
											dispatch(registerClient(obj))
										}}
									>
										{({ isSubmitting }) => (
											<Form className='ui form'>
												<Card>
													<Card.Content>
														<Card.Header textAlign='center'>
															Sitio del Cliente
														</Card.Header>
														<Card.Description>Registrarse</Card.Description>
													</Card.Content>
													<Card.Content>
														<Field
															type='text'
															name='name'
															component={Input}
															label='Nombre'
														/>
														<Field
															type='number'
															name='cellphone'
															component={Input}
															label='Teléfono'
															onInput={handleCellphoneInput}
														/>
														<Field
															type='email'
															name='email'
															component={Input}
															label='Correo electrónico'
														/>
														<Field
															type='password'
															name='password'
															component={Input}
															label='Contraseña'
														/>
														<Field
															type='password'
															name='passwordConfirm'
															component={Input}
															label='Repite contraseña'
														/>

														<Button
															style={{
																margin: '1rem 0',
																width: '100%',
															}}
															className='general-color'
															type='submit'
															content={'Registrarse'}
														/>
													</Card.Content>
												</Card>
											</Form>
										)}
									</Formik>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Link
										to='/login_client'
										style={{ color: 'black', fontSize: 15 }}
									>
										¿Ya tienes cuenta?{' '}
										<span style={{ color: '#0e1d48' }}>Inicia sesión aquí</span>
									</Link>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}

export default ClientRegister
