import crudHandlers from './_crud'

const initialState = {
	clients: [],
}

export default (state = initialState, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_CLIENTS': {
			return {
				...state,
				clients: action.response ?? []
			}
		}

		default:
			state = crudHandlers(state, action, 'client')

			return state
	}
}
