import crudHandlers from './_crud'

const initialState = {
	offers: [],
	offersById: null,
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_OFFERS':
			return { ...state, offers: payload }
		case 'LOAD_OFFERS_BY_ID':
			return { ...state, offerslById: payload }
		default:
			return state
	}
}
