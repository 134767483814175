import crudHandlers from './_crud'

const initialState = {
    entities: {},
    is_fetching: false,
    active: undefined,
	quantityOfOrders: 0,
 };

export default (state = initialState, action, entity, error, parseBody) => {
	let { type, response, payload } = action
	switch (type) {
		case `UPDATE_ORDER_NOTIFIERS`: {
			let newEntities = {}
			response.forEach((order) => {
				const entity_id = order.id || order._id
				let org = state.entities[entity_id]
				newEntities[entity_id] = { ...org, ...order }
			})

			return {
				...state,
				entities: {
					...state.entities,
					...newEntities,
				},
			}
		}
        case 'QUANTITY_OF_ORDERS':{
            return{
                ...state,
                quantityOfOrders: payload,
                is_fetching: false,
            }
        }
		default:
			state = crudHandlers(state, action, 'order_notifier')

			return state
	}
}
