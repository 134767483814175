import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

//  Reducers
import appReducer from './reducers/app'
import restaurant_dataReducer from './reducers/restaurant_datas'
import driverReducer from './reducers/drivers'
import clientReducer from './reducers/clients'
import routeReducer from './reducers/routes'
import userReducer from './reducers/users'
import roleReducer from './reducers/roles'
import sysCompanyReducer from './reducers/sys_companys'
import sysModuleReducer from './reducers/sys_modules'
import categoryReducer from './reducers/categorys'
import personalReducer from './reducers/personals'
import rel_waiter_tableReducer from './reducers/rel_waiter_table'
import productReducer from './reducers/product'
import tableReducer from './reducers/tables'
import food_extraReducer from './reducers/food_extras'
import waiterReducer from './reducers/waiters'
import offerReducer from './reducers/offers'
import walletReducer from './reducers/wallet'
import announcementReducer from './reducers/announcements'
import eventReducer from './reducers/events'
import stateReducer from './reducers/states'
import orderNotifierReducer from './reducers/order_notifier'
import clientOrderReducer from './reducers/clients_orders'
import rel_order_detailReducer from './reducers/rel_order_detail_food_extras'
import branchOfficeReducer from './reducers/branch_office'
import food_extras_categorysReducer from './reducers/food_extras_categorys'
import suggestions_complaintsReducer from './reducers/suggestions_complaints'
import kitchenReducer from './reducers/kitchen'
import visitReducer from './reducers/visit'
import menu_preferencesReducer from './reducers/menu_preferences'
import questionsReducer from './reducers/question'

//Middleware
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
	const store = createStore(
		combineReducers({
			app: appReducer,
			restaurant_datas: restaurant_dataReducer,
			drivers: driverReducer,
			clients: clientReducer,
			routes: routeReducer,
			users: userReducer,
			roles: roleReducer,
			sys_companys: sysCompanyReducer,
			sys_modules: sysModuleReducer,
			categorys: categoryReducer,
			products: productReducer,
			tables: tableReducer,
			food_extras: food_extraReducer,
			personals: personalReducer,
			rel_waiter_tables: rel_waiter_tableReducer,
			waiters: waiterReducer,
			offers: offerReducer,
			wallets: walletReducer,
			announcements: announcementReducer,
			events: eventReducer,
			states: stateReducer,
			order_notifiers: orderNotifierReducer,
			client_orders: clientOrderReducer,
			rel_order_detail_food_extrass: rel_order_detailReducer,
			branch_offices: branchOfficeReducer,
			food_extra_categorys: food_extras_categorysReducer,
			suggestions_complaints: suggestions_complaintsReducer,
			kitchen: kitchenReducer,
			visits: visitReducer,
			menu_preferences: menu_preferencesReducer,
			questions: questionsReducer,
		}),
		composeEnhancers(applyMiddleware(thunk))
	)
	return store
}
