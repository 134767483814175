import Swal from 'sweetalert2'
import { callApi } from 'utils/api'
import { showErrorMessage, showSuccessMessage } from 'utils/messages'

export const login = ({ username, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login`, {
				method: 'POST',
				body: JSON.stringify({
					username,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					console.log(res.body);
					const token = res.body.token
					localStorage.setItem('@token', token)

					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const loginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)
					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const loginWithExternalToken = (token, platform) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/client/verify_${platform}_token`, {
				method: 'POST',
				body: JSON.stringify({ token }),
				shouldSkipStatusError: true
				// errorMessage: { message:{ error: 'Tu sesión ha expirado'}, statusError: true },
			})

			if(res.status === 200) {
				dispatch({
					type: 'LOGIN_CLIENT',
					payload: res.body,
				})
			} else if(res.status === 401) {
				Swal.fire({
					title: 'Tu sesión ha expirado',
					text: 'Por favor, inicia sesión de nuevo',
					icon: 'warning',
					confirmButtonText: 'Ok',
					allowOutsideClick: false,
				}).then(() => {
					localStorage.removeItem('@external_token');
					window.location.href = '/login_client';
				})
			}
		} catch (error) {
			
		}
	}
}

export const logout = () => {
	try {
		localStorage.removeItem('@token')
		localStorage.removeItem('@employee_code')
	} catch (e) {
		// remove error
	}
	return {
		type: 'LOGOUT',
	}
}

export const updatePassword = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/user/change_password`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
				successMessage: 'Se actualizó correctamente',
			})

			dispatch({
				type: 'UPDATE_PASSWORD',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const wialonLogin = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/wialon/login`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
			})

			dispatch({
				type: 'LOGIN_WIALON',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const clientLogin = ({ user, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/client`, {
				method: 'POST',
				body: JSON.stringify({
					access: user,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@client_token', token)
					dispatch({
						type: 'LOGIN_CLIENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const recoveryPassword = ({ email }) => {
	return async () => {
		try {
			const res = await callApi(`/utils/password_recovery`, {
				method: 'POST',
				body: JSON.stringify({
					email,
				}),
			})

			console.log(res.body)
			showSuccessMessage('Correo enviado correctamente')
			return res.body
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const changePassword = ({ token, password }) => {
	return async () => {
		try {
			const res = await callApi('/utils/change_password', {
				method: 'POST',
				body: JSON.stringify({
					token,
					password,
				}),
				successMessage: 'Contraseña actualizada correctamente',
			})
		} catch (error) {}
	}
}

export const verifyToken = (token) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/utils/verify_token`, {
				method: 'POST',
				body: JSON.stringify({
					token,
				}),
			})

			console.log(res.body)
			return true
		} catch (error) {
			return false
		}
	}
}

export const clientLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/client/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.client_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@client_token', token)
					dispatch({
						type: 'LOGIN_CLIENT',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const clientLogout = () => {
	try {
		localStorage.removeItem('@client_token')
		localStorage.removeItem('@external_token');
	} catch (e) {
		// remove error
	}
	return { type: 'CLIENT_LOGOUT' };
}

export const driverLogin = ({ user, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/driver`, {
				method: 'POST',
				body: JSON.stringify({
					user,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@driver_token', token)

					dispatch({
						type: 'LOGIN_DRIVER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
export const driverLoginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/driver/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.driver_token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@driver_token', token)
					dispatch({
						type: 'LOGIN_DRIVER',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const driverLogout = () => {
	try {
		localStorage.removeItem('@driver_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'DRIVER_LOGOUT',
	}
}
export const passengerLogout = () => {
	try {
		localStorage.removeItem('@passenger_token')
	} catch (e) {
		// remove error
	}
	return {
		type: 'PASSENGER_LOGOUT',
	}
}

export const excelLayout = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/${options.entity_name}/generate_layout_excel`, {
			method: 'PUT',
			headers: {
				Authorization: state.app.user.token,
			},
		})
			.then((res) => {
				let buff = Buffer.from(res.body)
				//res.body.blob();
				return buff
			})
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(new Blob([blob]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `${options.entity_name}_layout.xlsx`)

				// Append to html link element page
				document.body.appendChild(link)

				// Start download
				link.click()

				// Clean up and remove the link
				link.parentNode.removeChild(link)
			})
	}
}

export const importFile = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/${options.entity_name}/import_data_from_excel`,
			{
				method: 'PUT',
				json: false,
				headers: {
					Authorization: state.app.user.token,
				},
				body: {
					file: options.files,
				},
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

export const verifyImportData = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(
			`/${options.entity_name}/validate_data_from_import`,
			{
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify(options.data),
			}
		)

		dispatch({
			type: 'IMPORT',
			payload: res.body,
		})

		return res.body
	}
}

export const getSysCompanyName = (sys_company_id) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/sys_company/getName/${sys_company_id}`, {
				method: 'GET',
			})

			if (res.status === 200) {
				const companyName = res.body.name
				dispatch({
					type: 'SET_COMPANY_NAME',
					payload: companyName,
				})
			} else {
				throw new Error('No se pudo obtener el nombre de la empresa')
			}
		} catch (error) {
			console.error(error.message.error)
		}
	}
}

export const create_food_stall = (
	sysCompanyData,
	userData,
	tablesAndWaiters,
	image,
	backgroundImg,
) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/app/create_food_stall`, {
				method: "POST",
				json: false,
				headers: {
					Authorization: state.app.user?.token,
				},
				body: {
					sysCompany: JSON.stringify(sysCompanyData),
					user: JSON.stringify(userData),
					tablesAndWaiters: JSON.stringify(tablesAndWaiters),
					image,
					backgroundImg,
					},
				successMessage: "Los datos han sido grabados con éxito.",
			});
			if(res.status == 200){
				dispatch({
					type: 'ADD_SYS_COMPANY',
					response: {
						...res.body.sysCompany,
						Users: [res.body.user],
						ExtraData: res.body.restaurantData,
					}
				})
			}
		} catch (error) {
			console.error(
				"Ha habido un problema con la operación de grabado: ",
				error
			);
		}
	}
}

export const update_food_stall = (sysCompanyData, userData, image, backgroundImg,) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()

			const res = await callApi(`/app/update_food_stall`, {
				method: "POST",
				json: false,
				headers: {
					Authorization: state.app.user?.token,
				},
				body: {
					sysCompany: JSON.stringify(sysCompanyData),
					user: JSON.stringify(userData),
					image,
					backgroundImg,
					},
				successMessage: "Los datos han sido grabados con éxito.",
			});
			
			if(res.status == 200){
				dispatch({
					type: 'UPDATE_SYS_COMPANY',
					response: {
						...res.body.sys_company_entity,
						Users: [res.body.user_entity],
						ExtraData: res.body.restaurant_data_entity,
					}
				})
			}
		} catch (error) {
			console.error(
				"Ha habido un problema al actualizar el puesto.",
				error
			);
		}
	}
}

export const setLanguage = (lang) => ({type: 'SET_LANGUAGE', payload: lang})