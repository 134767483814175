import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'LOAD_TABLES':
			return {
				...state,
				entities: action.payload,
			}

		default:
			state = crudHandlers(state, action, 'table')

			return state
	}
}
