
import crudHandlers from './_crud'

export default (state, action, entity, error, parseBody) => {
	let { type, response, payload } = action
	switch (type) {
		default:
			state = crudHandlers(state, action, 'rel_order_detail_food_extras')

			return state
	}
}