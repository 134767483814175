import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'

import personalActions from 'store/actions/personals'
import tableActions from 'store/actions/tables'
import rel_waiter_tableActions from 'store/actions/rel_waiter_table'

import PaginatedTable from 'semantic-table/dist/components/PaginatedTable'
import { getStateValue } from 'store/selectors'
import Swal from 'sweetalert2'

import {
	Breadcrumb,
	Modal,
	Button,
	Header,
	Icon,
	Checkbox,
	Dropdown,
	Table,
} from 'semantic-ui-react'
import { showErrorMessage, showSuccessMessage } from 'utils/messages'

const sections = [{ key: 'Asignar Mesas', content: 'Asignar Mesas' }]

const AssignTablesPage = (props) => {
	const [allWaitersSelected, setAllWaitersSelected] = useState(false)
	const { loadTables, tables, personal } = props
	const [openModal, setOpenModal] = useState(false)
	const [currentItem, setCurrentItem] = useState({
		selectedTable: {},
		selectedPersonal: null,
	})
	const [selectAll, setSelectAll] = useState(false)

	const loadData = async () => {
		await loadTables()
		await props.loadPersonal()
		await props.loadRelations({})
	}

	useEffect(() => {
		loadData()
	}, [])

	useEffect(() => {
		if (currentItem.selectedPersonal) {
			const relationForWaiter = props.relations.find(
				(relation) => relation.personal_id === currentItem.selectedPersonal
			)
			const assignedTablesForWaiter = relationForWaiter
				? relationForWaiter.table_ids
				: []
			const newSelectedTables = {}
			props.tables.forEach((table) => {
				newSelectedTables[table.id] = assignedTablesForWaiter.includes(table.id)
			})
			setCurrentItem((prev) => ({
				...prev,
				selectedTable: newSelectedTables,
			}))
		}
	}, [tables])

	useEffect(() => {
		if (selectAll) {
			setAllWaitersSelected(true)
		} else {
			setAllWaitersSelected(false)
		}
	}, [selectAll])

	const optionsPersonal = useMemo(() => {
		return personal
			.filter((person) => person.clasification === 'MESERO')
			.map((waiter) => {
				return {
					text: waiter.name,
					value: waiter.id,
					...waiter,
				}
			})
	}, [personal])

	// useEffect(() => {
	// 	if (selectAll) {
	// 		setCurrentItem({
	// 			...currentItem,
	// 			selectedTable: personal.reduce((acc, user) => {
	// 				acc[user.id] = true
	// 				return acc
	// 			}, {}),
	// 		})
	// 	} else {
	// 		setCurrentItem({
	// 			...currentItem,
	// 			selectedTable: personal.reduce((acc, user) => {
	// 				acc[user.id] = false
	// 				return acc
	// 			}, {}),
	// 		})
	// 	}
	// }, [selectAll])

	const modalTableAsignHandleSave = () => {
		const selectedTableArray = Object.keys(currentItem.selectedTable).filter(
			(id) => currentItem.selectedTable[id]
		)

		if (selectedTableArray.length === 0) {
			Swal.fire({
				icon: 'warning',
				title: '¡Advertencia!',
				text: 'Debes seleccionar al menos una mesa antes de guardar.',
			})
			return
		}

		if (allWaitersSelected) {
			const meseros = personal.filter(
				(person) => person.clasification === 'MESERO'
			)
			meseros.forEach((waiter) => {
				const data = {
					personal_id: waiter.id,
					table_ids: selectedTableArray,
				}

				props.createWaiterTableRelation(data, (success) => {
					if (!success) {
						showErrorMessage(
							`Error al asignar las mesas al mesero ${waiter.name}`
						)
					}
				})
			})
			showSuccessMessage(
				'Las mesas se asignaron correctamente a todos los meseros'
			)
		} else if (currentItem.selectedPersonal != null) {
			const data = {
				personal_id: currentItem.selectedPersonal,
				table_ids: selectedTableArray,
			}

			props.createWaiterTableRelation(data, (success) => {
				if (success) {
					showSuccessMessage('Las mesas se asignaron correctamente')
				} else {
					showErrorMessage('Error al asignar las mesas')
				}
			})
		} else {
			Swal.fire({
				icon: 'warning',
				title: '¡Advertencia!',
				text: 'Debes seleccionar un mesero o usar "Seleccionar todos" antes de guardar.',
			})
			return
		}

		loadData()
		window.location.reload()
		setOpenModal(false)
	}

	const formattedRelations = props.relations.map((relation) => ({
		...relation,
		table_names: Array.isArray(relation.table_names)
			? relation.table_names.join(', ')
			: relation.table_names,
	}))

	return (
		<div className='PageContainer'>
			<Breadcrumb icon='right angle' sections={sections} />
			<PaginatedTable
				title='Asignar Mesas'
				editable={true}
				rows={formattedRelations}
				maxRows={200}
				onSelect={(row) => {
					const relationForSelectedWaiter = props.relations.find(
						(relation) => relation.personal_id === row.personal_id
					)
					const tablesForSelectedWaiter = relationForSelectedWaiter
						? relationForSelectedWaiter.table_ids
						: []
					const selectedTablesObject = tablesForSelectedWaiter.reduce(
						(acc, tableId) => {
							acc[tableId] = true
							return acc
						},
						{}
					)
					setCurrentItem({
						selectedPersonal: row.personal_id,
						selectedTable: selectedTablesObject,
					})
					setOpenModal(true)
				}}
				onDelete={true}
				onCancel={true}
				actionsWidth={150}
				actionsActive={true}
				onSave={(data) => {
					props.bulkSync(data)
				}}
				additionalHeaderButtons={[
					{
						label: 'Asignar Mesas',
						icon: 'male',
						action: () => {
							setOpenModal(true)
							setCurrentItem({
								selectedTable: {},
								selectedPersonal: null,
							})
						},
					},
				]}
				columns={[
					{
						width: 200,
						Header: 'Mesero',
						accessor: 'waiter_name',
						filterable: true,
						sortable: true,
						editable: true,
					},
					{
						width: 500,
						Header: 'Mesas asignadas',
						accessor: 'table_names',
						filterable: true,
						sortable: true,
						editable: true,
					},
				]}
			/>
			<Modal
				closeIcon
				open={openModal}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
			>
				<Header icon='male' content='Asignar Mesas' />
				<Modal.Content>
					<h3>Selección de Meseros</h3>

					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>
									<Checkbox
										label={
											<label style={{ fontWeight: 'bold' }}>
												Seleccionar todos
											</label>
										}
										checked={selectAll}
										onChange={(e, { checked }) => {
											setSelectAll(checked)
										}}
									/>
								</Table.HeaderCell>
								<Table.HeaderCell>
									<Dropdown
										placeholder='Selecciona un Mesero'
										value={currentItem.selectedPersonal}
										fluid
										selection
										options={[...optionsPersonal]}
										onChange={(e, { value }) => {
											setCurrentItem((prevItem) => ({
												...prevItem,
												selectedPersonal: value,
											}))
										}}
									/>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
					</Table>

					<h3>Lista de Mesas</h3>

					<Table celled>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>
									<Icon name='unlock' />
								</Table.HeaderCell>
								<Table.HeaderCell>Código</Table.HeaderCell>
								<Table.HeaderCell>Nombre de la mesa</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{tables.map((table, index) => (
								<Table.Row key={table.id}>
									<Table.Cell style={{ width: '50px' }}>
										{console.log(currentItem.selectedTable[table.id])}
										<Checkbox
											checked={currentItem.selectedTable[table.id] === true}
											onChange={(e, { checked }) => {
												setCurrentItem((prevItem) => ({
													...prevItem,
													selectedTable: {
														...prevItem.selectedTable,
														[table.id]: checked,
													},
												}))
											}}
										/>
									</Table.Cell>
									<Table.Cell>{table.code}</Table.Cell>
									<Table.Cell>{table.name}</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
					</Table>
				</Modal.Content>

				<Modal.Actions>
					<Button color='red' onClick={() => setOpenModal(false)}>
						Cancelar
					</Button>
					<Button color='green' onClick={modalTableAsignHandleSave}>
						Grabar
					</Button>
				</Modal.Actions>
			</Modal>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => ({
	loadRelations: (options) => dispatch(rel_waiter_tableActions.load(options)),
	loadPersonal: () => dispatch(personalActions.getPersonal()),
	loadTables: () => dispatch(tableActions.loadTables()),
	bulkSync: (data) => dispatch(rel_waiter_tableActions.bulkSync(data)),
	createWaiterTableRelation: (data, callback) =>
		dispatch(rel_waiter_tableActions.createWaiterTableRelation(data, callback)),
})

const mapStateToProps = (state) => ({
	personal: state.personals.personal,
	tables: getStateValue(state.tables.entities),
	relations: getStateValue(state.rel_waiter_tables.entities),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssignTablesPage)
