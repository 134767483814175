import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PublicDeleteAccountRoute = ({
  isAuthenticated,
  component: Component,
  client,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        <Component {...props} />
      }
    />
  );
};

export default PublicDeleteAccountRoute;