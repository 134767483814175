import crud from './_crud'
import { callApi } from 'utils/api'
import { showSuccessMessage } from 'utils/messages'

const actions = crud('table', 'la mesa', 'las mesas')

const importTables = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/table/import_tables`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agreron las mesas correctamente.`,
		})
		showSuccessMessage('Se registraron las mesas correctamente')
		return res.body
	}
}

const loadTables = () => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/table/load_tables`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			dispatch({
				type: 'LOAD_TABLES',
				payload: res.body,
			})

			return res.body
		} catch (error) {}
	}
}

const getTable = (id) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/table/get_table/${id}`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			return res.body
		} catch (error) {}
	}
}

const bulkSyncTables = ({ deleteItems, updateItems, createItems }) => {
	return async (dispatch, getState) => {
		const state = getState()

		let res = await callApi(`/table/bulkSync`, {
			method: 'POST',
			body: JSON.stringify({
				deleteItems,
				updateItems,
				createItems,
			}),
			headers: {
				Authorization: state.app.user.token,
			},
			successMessage: `Se sincronizaron las mesas correctamente.`,
		})

		dispatch({
			type: 'LOAD_TABLES',
			payload: res.body.updatedItems,
		})

		return res.body.updatedItems
	}
}

export default {
	...actions,
	importTables,
	loadTables,
	getTable,
	bulkSyncTables,
}
