import crudHandlers from "./_crud";

const initialState = {
    entities: {},
    is_fetching: false,
    active: undefined,
    topTen: [],
  }

export default (state=initialState, action) => {
    const { type } = action;
    switch (type) {
        case 'TOP_TEN':
            return {
                ...state,
                topTen: action.payload
            }
        default:

            state = crudHandlers(state, action, 'product')

            return state
    }
}