import crudHandlers from './_crud'

const initialState = {
    entities: {},
    is_fetching: false,
    active: undefined,
	currentDayVisits: [],
    currentWeekVisits: [],
    specificWeekVisits: [],
    monthVisits: [],
    yearVisits: [],
    loadVisits: [],
    loading: false,
    error: null,
    clearAll: false,
  }

export default (state = initialState, action) => {
	const { type } = action
	switch (type) {
		case "SET_CURRENT_DAY_VISITS":
			return {
			  ...state,
			  currentDayVisits: action.payload,
			  loading: false,
			  error: null,
			};
		case 'SET_CURRENT_WEEK_VISITS':
			return {
				...state,
				currentWeekVisits: action.payload,
				loading: false,
				error: null,
			};
		case 'SET_MONTHLY_VISITS':
			return {
				...state,
				monthVisits: action.payload,
				loading: false,
				error: null,
			};
		case 'SET_YEARLY_VISITS':
			return {
				...state,
				yearVisits: action.payload,
				loading: false,
				error: action.payload,
			};
		default: {
			state = crudHandlers(state, action, 'visit')
			return state
		};
	}
}
