import { callApi } from "utils/api";
export default (
  entity,
  messageEntity,
  messagePluralEntity,
  storeEntity,
) => {

  const get = (id, options = {}) => {
    return async (dispatch, getState) => {
      const state = getState();
      dispatch({
        type: `FETCH_${entity.toUpperCase()}S`,
      })
      const res = await callApi(`/${entity}/${id}`, {
        method: 'GET',
        headers: {
          Authorization: state.app.user.token
        },
        params: options.params
      })

      dispatch({
        type: `LOAD_${entity.toUpperCase()}`,
        response: res.body,
        payload: id
      })


      return res.body;
    }
  }
  const load = (options = {}) => {
    return async (dispatch, getState) => {
      const state = getState();
      //  Check if the entities have been loaded before
      if (Object.keys(state[`${entity}s`]?.entities ?? state[`${storeEntity}s`].entities).length <= 2 || options.force === true) {
        dispatch({
          type: `FETCH_${storeEntity ? storeEntity.toUpperCase() : entity.toUpperCase()}S`,
        })
        const res = await callApi(`/${entity}`, {
          method: 'GET',
          headers: {
            Authorization: state.app.user.token
          },
          params: options.params
        })
        dispatch({
          type: `LOAD_${storeEntity ? storeEntity.toUpperCase() : entity.toUpperCase()}S`,
          response: res.body,
        })


        return res.body;
      }

    }
  }

  const add = (item, options = {}) => {

    const {showMessage = true} = options

    return async (dispatch, getState) => {
      const state = getState();
      //  It first add the Item

      let res = await callApi(`/${entity}`, {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          Authorization: state.app.user.token
        },
        successMessage: showMessage ? `Se agregó ${messageEntity} correctamente.` : undefined
      })

      let stored_item = res.body
      return dispatch({
        type: `ADD_${entity.toUpperCase()}`,
        response: stored_item
      })

    }
  }

  const remove = item => {
    return async (dispatch, getState) => {
      const state = getState();
      const res = await callApi(`/${entity}/${item.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: state.app.user.token
          },
          successMessage: `Se eliminó ${messageEntity} correctamente.`
        })
      dispatch({
        type: `DELETE_${entity.toUpperCase()}`,
        response: res.body
      })
    }
  }

  const update = (item, shouldShowSuccessMessage = true) => {
    return async (dispatch, getState) => {
      const state = getState();
      let res = await callApi(`/${entity}/${item.id}`, {
        method: 'PATCH',
        body: JSON.stringify(item),
        headers: {
          Authorization: state.app.user.token
        },
        successMessage: shouldShowSuccessMessage && `Se actualizó ${messageEntity} correctamente.`
      })

      let stored_item = res.body

      dispatch({
        type: `UPDATE_${entity.toUpperCase()}`,
        response: stored_item
      })



    }
  }

  const set = (id) => ({
    type: `SET_${entity.toUpperCase()}`,
    payload: id
  })

  const bulkSync = ({ deleteItems, updateItems, createItems }) => {
    return async (dispatch, getState) => {
      const state = getState();

      let res = await callApi(`/${entity}/bulkSync`, {
        method: 'POST',
        body: JSON.stringify({
          deleteItems,
          updateItems,
          createItems
        }),
        headers: {
          Authorization: state.app.user.token
        },
        successMessage: `Se sincronizaron ${messagePluralEntity} correctamente.`
      })

      let stored_item = res.body
      return dispatch(load({
        force: true
      }))
    }
  }

  const bulkCreate = (arrayOfItems) => {
    return async (dispatch, getState) => {
      const state = getState();
 
      let res = await callApi(`/${entity}/bulkCreate`, {
        method: 'POST',
        body: JSON.stringify(arrayOfItems),
        headers: {
          Authorization: state.app.user.token
        },
        successMessage: `Se sincronizaron ${messagePluralEntity} correctamente.`
      })
 
      let stored_items = res.body
      return dispatch(load())
    }
  }

  const list = (options = {}) => {
    return async (dispatch, getState) => {
      const state = getState();
      //  Check if the entities have been loaded before
      if (Object.keys(state[`${entity}s`].entities).length <= 2 || options.force === true) {
        dispatch({
          type: `FETCH_${entity.toUpperCase()}S`,
        })
        const res = await callApi(`/${entity}/list`, {
          method: 'POST',
          headers: {
            Authorization: state.app.user.token
          },
          params: options.params
        })

        dispatch({
          type: `LOAD_${entity.toUpperCase()}S`,
          response: res.body,
        })


        return res.body;
      }

    }
  }

  return {
    get,
    load,
    add,
    remove,
    update,
    set,
    bulkSync,
    bulkCreate,
    list
  }
}
