import crudHandlers from './_crud'

const initialState = {
	waiters: [],
    waitersById: null,
	// personalById: null,
}
export default (state = initialState, action) => {
	const { type, payload } = action
	switch (type) {
		case 'LOAD_WAITERS':
			return { ...state, waiters: payload }
		default:
			// state = crudHandlers(state, action, 'waiters')

			return state
	}
}
