import React from 'react'
import AppRouter from "./routes/index";

import { Provider } from 'react-redux';
import configureStore from './store/';

import './styles/index.css';
import './styles/index.scss';
import './styles/menu.css'
import './styles/menu.scss'

const store = configureStore();

function App() {

  return (

    <Provider store={store}>
      <div>
        <AppRouter />
      </div>
    </Provider>
  );
}

export default App;
