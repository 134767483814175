import { callApi } from 'utils/api'
import crud from './_crud'
import { showSuccessMessage } from 'utils/messages'

const actions = crud('personal', 'El personal', 'Los personales')

const getPersonal = () => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi('/personal/get_personal', {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			dispatch({ type: 'LOAD_PERSONAL', payload: res.body })
		} catch (error) {}
	}
}

const getPersonalById = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/personal/get_personal/${id}`, {
				method: 'GET',
				headers: {
					Authorization: state.app.user.token,
				},
			})

			dispatch({ type: 'LOAD_PERSONAL_BY_ID', payload: res.body })
		} catch (error) {}
	}
}
//Actualizar un personal
const updatePersonal = (personal) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/personal/update_personal`, {
				method: 'PUT',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify({ personal }),
				successMessage: 'Personal actualizado correctamente',
			})
			return true;
		} catch (error) {
			// Manejar error
			return false;
		}
	}
}

//Insertar un personal
const createPersonal = (personal) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi('/personal/create_personal', {
				method: 'POST',
				headers: {
					Authorization: state.app.user.token,
				},
				body: JSON.stringify({ personal }),
				successMessage: 'Registro realizado correctamente',
			})
			return true;
		} catch (error) {
			// Manejar error
			return false;
		}
	}
}

//eliminar un personal

const deletePersonal = (id) => {
	return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/personal/delete_personal/${id}`, {
				method: 'DELETE',
				headers: {
					Authorization: state.app.user.token,
				},
				successMessage: 'Registro eliminado correctamente',
			})
		} catch (error) {
			// Manejar error
		}
	}
}

//Eliminar multiples registros del personal

const deletePersonalMultiple = (ids) => {
    return async (dispatch, getState) => {
		const state = getState()

		try {
			const res = await callApi(`/personal/delete_personal_multiple`, {
				method: 'DELETE',
				headers: {
					Authorization: state.app.user.token,
				},
                body: JSON.stringify({ ids }),
				successMessage: 'Registro eliminado correctamente',
			})
		} catch (error) {
			// Manejar error
		}
	}
}

const importPersonal = (options) => {
	return async (dispatch, getState) => {
		const state = getState()

		const res = await callApi(`/personal/import_personal`, {
			method: 'POST',
			headers: {
				Authorization: state.app.user.token,
			},
			body: JSON.stringify({
				options,
			}),
			successMessage: `Se agregó el personal correctamente.`,
		})
		showSuccessMessage('Se registró el personal correctamente')
		return res.body
	}
}

export default {
	...actions,
	getPersonal,
	getPersonalById,
	updatePersonal,
	createPersonal,
    deletePersonal,
    deletePersonalMultiple,
	importPersonal,
}
