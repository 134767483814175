import crudHandlers from './_crud'

export default (state, action, entity, error, parseBody) => {
  let { type, response, payload } = action
  switch (type) {
    default:
      // Para otras acciones, puedes utilizar el manejador genérico
      state = crudHandlers(state, action, 'client_order')

      return state
  }
}
