import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({
  isAuthenticated,
  lang,
  component: Component,
  user,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props) =>
        isAuthenticated ? (
          <Redirect to={{
            pathname: user?.redirect_url ?? user.role?.redirect_url ?? '/', 
            search: `?lang=${lang}`}}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const mapStateToProps = (state) => ({
  user: state.app.user,
  isAuthenticated: !!state.app.user,
  lang: state.app.lang ?? 'en',
});

export default connect(mapStateToProps)(PublicRoute);
