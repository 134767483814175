import React, { useState, useEffect } from 'react'
import { Button, Card, Segment, Grid, Image, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { Field, Form, Formik } from 'formik'
import Input from 'components/InputForm'
import { useDispatch, connect } from 'react-redux'
import { recoveryPassword } from 'store/actions/app'

import LogoImg from '../../images/logo1024.jpg'
import { showErrorMessage } from 'utils/messages'
function validate(email) {}
const PasswordRecovery = (props) => {
	const [sending, setSending] = useState(false)
	const dispatch = useDispatch()

	const onSubmit = async (values, { setSubmitting }) => {
		//Evento para mandar API
		const mail = String(values?.email).trim()
		if (mail.length > 0) {
			const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
			if (emailRegex.test(mail)) {
				setSending(true)
				const obj = {
					email: values.email,
				}
				await dispatch(recoveryPassword(obj))
				setSending(false)
			} else {
				showErrorMessage({error: 'Introduce un correo electrónico válido'})
			}
		}
	}

	return (
		<div
			style={{
				width: '100wh',
				height: '100vh',
				justifyContent: 'center',
				alignItems: 'center',
				padding: '20px',
			}}
			className='login-background'
		>
			<div
				style={{
					display: 'flex',
					height: '20vh',
					position: 'fixed',
					top: 0,
					left: 0,
				}}
			></div>
			<Grid centered>
				<Grid.Row>
					<Grid.Column computer={6} tablet={8} mobile={16}>
						<Segment>
							<Grid stackable>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<div
										onClick={() => {
											window.location.href = '/login'
										}}
										style={{ cursor: 'pointer' }}
									>
										<Image
											src={process.env.REACT_APP_LOGO_LOGIN_URL ?? LogoImg}
											size='medium'
										/>
									</div>
								</Grid.Row>
								<Grid.Row
									style={{
										alignItems: 'center',
										justifyContent: 'center',
									}}
									textAlign='center'
								>
									<Formik
										initialValues={{}}
										onSubmit={onSubmit}
									>
										{({ isSubmitting }) => (
											<Form className='ui form'>
												<Card>
													<Card.Content>
														<Card.Header textAlign='center'>
															Sitio del Cliente
														</Card.Header>
														<Card.Description>
															Recuperación de contraseña
														</Card.Description>
													</Card.Content>
													<Card.Content>
														<Field
															type='email'
															name='email'
															component={Input}
															label='Correo electrónico'
														/>
														<Button
															style={{
																margin: '1rem 0',
																width: '100%',
															}}
															className='general-color'
															type='submit'
															content={
																<>
																	{sending ? (
																		<Loader
																			size='mini'
																			active
																			inline='centered'
																		>
																			Enviando
																		</Loader>
																	) : (
																		'Enviar'
																	)}
																</>
															}
														/>
													</Card.Content>
												</Card>
											</Form>
										)}
									</Formik>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	)
}
const mapDispatchToProps = (dispatch) => ({
	recoveryPassword: (email) => dispatch(recoveryPassword(email)),
})

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecovery)
